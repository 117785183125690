.size-chart {
    .bits-table {
        margin-top: 60px;
        padding: 0 !important;
    }

    .module-copy-block {

        padding: 60px 0 0 !important;

        h3 {
            border-bottom: solid 1px #E7E5E4;
            margin-left: -24px;
            margin-right: -24px;
            margin-bottom: 50px !important;
            padding-bottom: 40px;
            padding-left: 24px;
            padding-right: 24px;
        }

        h4 {
            font-size: 14px !important;
        }

        p {
            font-size: 14px !important;
            line-height: 22px !important;

            b:first-of-type {
                display: block;
                margin-bottom: 12px;
            }
        }

        article {
            padding-left: 24px;
            padding-right: 24px;
        }

        article.text-center h3 {
            text-align: center !important;
        }


        article.text-left h3 {
            text-align: left !important;
        }

        article.text-right h3 {
            text-align: right !important;
        }


        .module-copy-block-image {
            background-color: #F7F7F7;
        }

        ol {
            margin-bottom: 0 !important;
            padding-left: 40px !important;
            padding-bottom: 0 !important;
            counter-reset: item;

           & > li {
                margin-bottom: 40px;
                padding-left: 20px !important;
                position: relative;
                list-style: none;
                p {
                    font-size: 14px !important;
                    text-align: left !important;

                    b:first-of-type {
                        font-size: 16px;
                        display: block;
                        margin-bottom: 12px;
                    }
                }

            }

           & > li::before {
                border: solid 1px #E7E5E4;
                counter-increment: item;
                content: counter(item);
                margin-right: 20px;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: -40px;
                top: 0;
              }
        }
    }
}

@screen md {
    .size-chart {
        .module-copy-block {
            h3 {
                margin-left: -50px !important;
                margin-right: -50px !important;
                padding-left: 50px;
                padding-right: 50px;
            }

            article {
                padding-left: 50px;
                padding-right: 50px;
            }

        }
    }
}