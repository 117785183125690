.module-markdown {

    table {
        margin-top: 0;
        margin-bottom: 0;

        thead {
            border: none;
            tr {
                background-color: #F0F0EF;
                height: 40px;
                font-size: 10px;
                text-transform: uppercase;

                th {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    letter-spacing: .01em;
                    padding: 0;
                    width: 100%;
                }

                th:first-of-type {
                    padding-left: 15px;
                }

                th:last-of-type {
                    padding-right: 15px;
                }
            }
        }

        tbody {

            tr {
                border: none;
                font-weight: 400;
                height: 40px;
                letter-spacing: .01em;
                text-transform: capitalize;

                td {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    font-size: 14px;
                    padding: 0;
                    text-align: left;
                    width: 100%;
                }

                td:first-of-type {
                    padding-left: 15px;
                }

                td:last-of-type {
                    padding-right: 15px;
                }

                &:nth-child(even) {
                    background-color: #F7F7F7;
                }
            }
        }
    }
}