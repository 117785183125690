.rich-text-content {
    h1 {
        font-size: 40px;
        line-height: 38px;
        letter-spacing: -0.5px;
    }

    h2 {
        margin-bottom: 30px !important;
        font-size: 1.5rem;
        line-height: 28px;
        font-family: 'Proxima Nova';
        font-weight: 600;
        position: relative;
        letter-spacing: 0.01em;
        font-style: normal;
        text-transform: none;
    }

    h3 {
        font-size: 1.1rem;
        line-height: 18px;
        letter-spacing: -0.5px;
    }

    h4 {
        font-size: 18px;
        line-height: 16px;
    }

    h5 {
        font-size: 17px;
        line-height: 15px;
        letter-spacing: 0.5px;
    }

    h6 {
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 0.5px;
    }

    h1,h2,h3,h4,h5,h6{
        text-transform: uppercase;
        font-weight: bold;

    }

    & .text-center  * {
        text-align: center !important;
    }

    & .module-markdown.container {
        max-width: 100% !important;
    }

    &.no-heading-top-margin {
        h1,h2,h3,h4,h5,h6 {
            margin-top: 0 !important;
        }
    }

    p:last-of-type {
        margin-bottom: 1.25em !important;
    }

}

@screen md {
    .rich-text-content {

        h1 {
            font-size: 50px;
            line-height: 45px;
            letter-spacing: -0.5px;
        }

        h2 {
            font-size: 36px;
            line-height: 34px;
            letter-spacing: -0.5px;
        }

        h3 {
            font-size: 22px;
            line-height: 20px;
            letter-spacing: -0.5px;
        }

        h4 {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.5px;
        }

        h5 {
            font-size: 19px;
            line-height: 17px;
            letter-spacing: 0.5px;
        }

        h6 {
            font-size: 18px;
            line-height: 16px;
            letter-spacing: 0.5px;
        }
    }
}

@screen lg {
    .rich-text-content {

        h1 {
            font-size: 65px;
            line-height: 55px;
            letter-spacing: 1px;
        }

        h2 {
            font-size: 40px;
            line-height: 38px;
            letter-spacing: -0.5px;
        }

        h3 {
            font-size: 28px;
            line-height: 26px;
            letter-spacing: -0.5px;
        }

        h4 {
            font-size: 22px;
            line-height: 20px;
            letter-spacing: -0.5px;
        }

        h5 {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }

        h6 {
            font-size: 18px;
            line-height: 16px;
            letter-spacing: 0.5px;
        }
    }
}

@screen xl {
    .rich-text-content {

        h1 {
            font-size: 80px;
            line-height: 70px;
            letter-spacing: 1px;
        }

        h2 {
            font-size: 55px;
            line-height: 53px;
            letter-spacing: -0.5px;
        }

        h3 {
            font-size: 32px;
            line-height: 30px;
            letter-spacing: -0.5px;
        }

        h4 {
            font-size: 25px;
            line-height: 23px;
            letter-spacing: -0.5px;
        }

        h5 {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }

        h6 {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }
    }
}