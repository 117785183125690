.product-image-modal_gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1024px;
  height: auto;
  width: 100%;
}

.product-image-modal_carousel {
  max-width: 100%;
  max-width: 446px; 
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.product-image-modal_carousel .swiper-container {
  padding: 0 70px 70px;
}

@media (max-width: 768px) {
  .product-image-modal_carousel {
    max-width: 100%;
    padding-left:0;
    padding-right:0;
  }

  .product-image-modal_carousel .swiper-container {
    padding: 0 0 70px;
  }
}

@media (min-width: 768px) {
  .product-image-modal_carousel {
    max-width: 600px;
  }
}

@media (min-width: 1024px) {
  .product-image-modal_carousel {
    max-height: 1200px;
    max-width: 1000px;
    
  }
}

.product-image-modal_carousel .swiper-container {
  height: 100%;
}

.product-image-modal__close-button {
  top: 40px;
  right: 40px;
}

.product-image-modal__image-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1024px;
}

.product-image-modal_thumbnails {
  padding: 0 12px;
  min-height: 15% !important;
  max-width: 750px;
  min-width: auto;
  margin: 0 auto;
}

.product-image-modal_thumbnails .swiper-slide {
  opacity: 0.3;
}

.product-image-modal_thumbnails .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.product-image-modal_carousel .swiper-button-next:after, 
.product-image-modal_carousel .swiper-button-prev:after {
  content: '';
}

.product-image-modal_carousel .swiper-button-prev, 
.product-image-modal_carousel .swiper-button-next {
  width: 43px;
  top: calc(50% - 20px);
  z-index: 2;
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 3px;
  box-shadow: 0px 2px 3px rgb(52 52 52 / 40%);
  transform: translateY(-50%);
}
.product-image-modal_carousel .swiper-button-prev {
  left: 3px;
}

.product-image-modal_carousel .swiper-button-next:before {
  content: "";
  top: calc(50% - 4px);
  right: 18px;
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% + 2px);
}
.product-image-modal_carousel .swiper-button-next:after {
  content: "";
  top: calc(50% - 4px);
  right: 18px;
  width: 10px;
  height: 2px;
  transform: rotate(45deg);
  background: #000;
  position: absolute;
}

.product-image-modal_carousel .swiper-button-prev:before {
  content: "";
  top: calc(50% - 4px);
  left: 18px;
  width: 10px;
  height: 2px;
  transform: rotate(45deg);
  background: #000;
  position: absolute;
  top: calc(50% + 2px);
}
.product-image-modal_carousel .swiper-button-prev:after {
  content: "";
  top: calc(50% - 4px);
  left: 18px;
  width: 10px;
  height: 2px;
  transform: rotate(-45deg);
  background: #000;
  position: absolute;
}

@media (max-width: 768px) {
  .product-image-modal_carousel .swiper-button-prev {
    display: none;
  }

  .product-image-modal_carousel .swiper-button-next {
    display: none;
  }
}

.product-image-modal_carousel .iiz div:first-child {
  height: 100%;
}


.product-image-modal_carousel .iiz__img {
  object-fit: contain;
  width: auto;
  /* height: 100%; */
  margin-left: auto;
  margin-right: auto;
}
/* 
.iiz__zoom-img--visible {
  width: 1200px !important;
  height: 1200px !important;
} 
*/
@media (min-width: 768px) {
  .product-image-modal__image-wrap .iiz {
    max-width: 600px;
  }
}