
/* Style to override modules in pro program page */
.pro-program-module-wrap {

    .content-up {
        display: none !important;
        padding-top: 0 !important;
    }

    & > div:first-child {
        a {
            margin-right: 0 !important;
        }
    }

    .module-featured-buybox {
        margin-bottom: 50px !important;
    }

    .module-content-2images-article-size-by-side {
        padding-bottom: 0 !important;
        min-height: 100% !important;
        margin-bottom: 50px !important;
    }
}

@screen md {

    .pro-program-module-wrap {
        .heroWihoutBtn-sub {
            margin-top: -2px !important;
        }

        .content-up {
            display: block !important;
        }

        .module-featured-buybox {
            margin-bottom: 70px !important;
        }

        .module-content-2images-article-size-by-side {
            margin-bottom: 100px !important;
        }
    }
}