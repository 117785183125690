.buy-box__thumbnails-slider .swiper-slide {
    height: auto!important;
}

.buy-box__thumbnails-slider .swiper-container {
    height: 100%;
}

.buy-box__description ol,
.buy-box__description ul {
    list-style: revert;
    margin: unset;
    padding: revert;
}
