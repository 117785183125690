.article-content-block {
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h2 {
    margin-top: 50px;
    margin-bottom: 30px !important;
    font-size: 1.5rem;
    line-height: 28px;
    font-family: 'Proxima Nova';
    font-weight: 600;
    text-align: left;
    position: relative;
    letter-spacing: 0.01em;
    padding-left: 24px;
    font-style: normal;
    text-transform: none;
  }

  h2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background: #dd5f13;
  }

  h3 {
    font-size: 1.1rem;
    text-align: left;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply mb-4;
  }

  .blockquote {
    padding-top: 85px;
    padding-bottom: 58px;
  }

  .tracking-widest p {
    text-align: justify;
    margin-bottom: 10px;
  }
}

 /* css for article page > content block  */
 .main-block{
  display: flex;
 }
 .second-block{
  background-color:#F7F7F7;
  width:50%;
  height:400px;
  padding:40px
}

@media screen and (max-width: 600px){
  .main-block{
    display: block;
  }
  .second-block{
  width: 100%;
  margin-top: -6px;
  }
}

 /* ends here */

@screen md {
  .article-content-block {
    h2 {
      font-size: 2.75rem;
      line-height: 52px;
    }

    h3 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1.25rem;
    }
  }
}
