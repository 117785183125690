.promobar {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.01em;
}

.promobar .promobar__message {
    padding: 16px 0px;
}

.promobar a {
    border-bottom: 1px solid #000;
    padding: 5px 0px;
}