@media (max-width:720px){
    .btn-pos-fixed{
      position: fixed;
      bottom: 0px;
      box-shadow: 0px -2px 12px #b7b7b7;
      left: 0px;
      width: 100%;
      background: #fff;
      padding: 10px;
      align-items: center;
    }
    .open-modal{
      position: fixed;
      overflow: scroll;
      /* max-width: 380px; */
      top: 0;
      /* overflow-y: hidden; */
      bottom: 0;
      padding: 10px 0 px;
      /* left: 0; */
      margin-left: -24px;
      /* padding-right: 332220px; */
      /* right: 0; */
      /* height: 900px; */
      /* transform: translate(-8%,-8%); */
      background: white;
      /* /* height: 90px; */
    }
}