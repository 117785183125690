.product-image-thumbnail > span,
.product-image-thumbnail > span > span,
.product-image-main > span,
.product-image-main > span > span {
  @apply aspect-square;
}

.product-image-thumbnail > span > img,
.product-image-thumbnail > span > span > img,
.product-image-main > span > img,
.product-image-main > span > span > img {
  height: auto !important;
  width: 100% !important;
}



@media (max-width: 1024px) {

  .splide__pagination--product-gallery {
    bottom: -0.2em !important;
    width: 100% !important;
  }

  .splide__pagination--product-gallery li {
    flex: 1 ;
    width: 100%;
  }

  .splide__pagination__page--product-gallery {
    border-radius: 0px !important;
    height: 2px !important;
    width: 100% !important;
    opacity: 1 !important;
    margin: 0 !important;
  }

  .splide__pagination__page--product-gallery.is-active {
    background: black !important;
    transform: none !important;
  }
}


.splide__arrow--prev--product-gallery {
  top: 0 !important;
  display: flex !important;
}

.splide__arrow--next--product-gallery {
  bottom: 0 !important;
  display: flex !important;
}

@media (max-width: 1024px) {
  .splide__arrow--next--product-gallery {
    bottom: -46px !important;
  }
}

.splide__arrow--product-gallery {
  box-shadow: none !important;
  display: flex !important;
}

.splide__arrow--product-gallery svg path {
  fill: black !important;
  opacity: 0.4;
}
