.product-card {
    min-height: 480px;
}
.product-item-label {
    /* z-index: 1; */
    width: fit-content;
}
.colors-length {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #4C4442;
}
@media (min-width: 1280px) {
    .product-card-wrap-hover .product-card,
    .group:hover .product-card {
        background: #FFFFFF;
        border-radius: 2px;
        border: 20px solid white;
        box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.13);
        z-index: 2;
        position: absolute;
        min-height: 700px;
        top: -20px;
        left: -20px;
        width: 359px;
        /* width: 372px; */
        /* width: 22.4rem;
        width: 118%; */
        /* left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); */
    }
    .group:hover .product-card .hover-product-description {
       /*  max-height: 110px; */
        overflow: auto;
        /* border-left: 20px solid white;
        border-right: 20px solid white;
        border-bottom: 20px solid white; */
    }

    .product-card-hover-content {
        position: static !important;
    }
}

.hover-product-description li {
    list-style: inside;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: black;
}

@media (max-width: 768px) {
    .product-card {
        min-height: 300px;
    }
    .product-card .item-image-wrapper {
        min-height: 230px;
    }
    .image-wrapper {
        padding: 0 !important;
    }
    div.grid-info-box {
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@screen md {
    .product-card-wrap-hover {
        .product-card-hover-content {
            height: auto !important;
            opacity: 100% !important;
        }

        .product-card-hover-colors {
            opacity: 100% !important;
            height: auto !important;
        }

        .product-card-content {
            display: none;
        }
    }
}

