.block-bg-gray {
    background-color: rgb(247, 247, 247)
}

.block-bg-category {
    background-color: #97A3AE;
    padding: 20% 20%;
    text-align: center;
}

.category-grid-li {
    display: inline-block;
}
.social-responsibility-wrap {
  padding: 80px 0px 100px 0px !important;
}
.social-responsibility-wrap h3{
    margin-top: 50px;
    margin-bottom: 24px;
    text-align: left;
}
.social-responsibility-wrap a {
    color:#000;
    font-size:12px;
    line-height:15px;
    letter-spacing: 0.08em;
    margin: 24px 0;
}
.welcome-home-slider .splide__arrows button svg path {
  fill: black !important;
}
.grant-program-wrap {
  max-width: 1030px !important;
  margin: 0 auto;
}
@media(max-width: 1050px) {
  .grant-program-wrap {
    max-width: calc(100% - 50px) !important;
  }
}
.grant-program-wrap td{
  padding: 0;
}
.grant-program-wrap table {
  margin: 0;
}
.grant-program-wrap td:first-of-type {
  max-width: 615px;
}

.grant-program-wrap td:first-of-type p:first-of-type{
  margin: 0;
}
.grant-program-wrap td:first-of-type h3:first-of-type{
  margin-top: 0;
}
.grant-program-wrap h3{
  text-align: left;
  letter-spacing: 1.3px;
  margin-bottom: 22px;
}
.grant-program-wrap td:first-of-type h3:nth-of-type(2) {
  margin-top: 50px !important;
}
.grant-program-wrap td:nth-of-type(2) p {
  margin: 0;
}
.grant-program-wrap td:first-of-type p:last-child {
  margin-bottom: 0;
}
.revive-page .revive-content-wrap h4{
  text-align: left;
  margin-bottom: 22px;
}
.revive-page .revive-content-wrap h5{
  text-align: left;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color:#000;
  margin-bottom: 10px;
  margin-top: 30px;
}
.revive-page .revive-content-wrap h1{
  text-align: left;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}
.revive-page .revive-content-wrap a{
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
  color: #000;
  letter-spacing: 0.08em;
}
.revive-page .revive-content-wrap h4{
  margin-top:50px;

  color:#000;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.08em;
}
.revive-page .revive-content-wrap h4:nth-last-of-type(1){
  margin-top: 30px;
}
.revive-page .revive-content-wrap img:nth-last-of-type(1){
  margin-right: 0;
  margin-top: -100px;
}
.revive-page .revive-content-wrap{
  padding: 50px 0 20px 0;
}
.revive-page .revive-content-wrap img:first-of-type {
  margin: 100px 0 50px 0;
}
.revive-page .revive-content-wrap hr{
  border-color: #DD5F13 ;
  margin: 30px 0 0 0 ;
  height: 1px;
}
.revive-page .revive-content-wrap table td {
  padding: 0;
}
.revive-page .revive-content-wrap ol p {
  margin: 0;
  color:#000;
}
.revive-page .revive-content-wrap ol{
  padding-left: 18px;
}
.revive-page .revive-content-wrap ol li::marker{
  color:#000;
  font-size: 14px;
}
.revive-page .revive-content-wrap table td p{
  margin: 0;
}
.revive-page .revive-content-wrap table:first-of-type tr:first-of-type tr td:last-child p{
  text-align: right;
}
.revive-page .revive-content-wrap table {
  margin: 0;
  min-width: 305px;
}

.revive-content-wrap article {
  margin-top: 0 !important;
}

.revive-page .revive-content-wrap h4:first-of-type {
  margin-top: 0;
}

.revive-page .revive-content-wrap i{
  display: flex;

  font-family: 'FreightBig Pro';
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

.grant-program-page .block-article p{
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #4C4442;

}
.program-benefits article{
  margin: 0 !important;
}
.program-benefits h4{
  text-align: left;
  margin: 0 0 22px 0;
  color: #0C1319;
  letter-spacing: 0.08em;
  font-weight: 700;
}
.program-benefits ul{
  margin-bottom: 20px;
}
.program-benefits ul li{
  margin: 0;
  padding: 0;
}
.program-benefits ul li::marker{
  color: #0C1319;
}
.program-benefits ul p{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #0C1319;
}
@media (max-width:767px) {
  .program-benefits h4{
    margin-top: 30px;
  }
  .conservation-partners-slider .splide__arrow{
    margin-top: -17px;
  }
  .revive-page .revive-content-wrap h5{
    margin-bottom: 20px;
  }
  .revive-page .revive-content-wrap table:first-of-type tr:first-of-type{
    display: flex;
    flex-direction: column;
  }
  .revive-page .revive-content-wrap table:first-of-type tr:first-of-type tr{
    flex-direction: row;
  }

  .revive-page .revive-content-wrap h1{
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.08em;
  }
  .revive-page .revive-content-wrap img:nth-last-of-type(1){
    margin: 50px auto 0 auto;
    min-width: 151px;
  }
  .revive-page .revive-content-wrap{
    padding: 30px 0 20px 0;
  }
  .revive-page .revive-content-wrap img {
    margin: 50px 0;
  }
  .revive-page .revive-content-wrap h4{
    margin-top:30px;
  }

  /* .splide.is-active .splide__list {
    margin-top: 32px !important;
  } */

  .grant-program-slider {
    padding: 20px 0 50px 0 !important;
  }
  .social-responsibility-wrap {
    padding: 30px 0px 100px 0px !important;
  }
  .social-responsibility-wrap h3{
    font-size:20px !important;
    line-height: 24px !important;
  }

  .grant-program-wrap {
    padding-top: 24px !important;
  }

  .grant-program-wrap h3{
    font-size: 20px;
    line-height: 24px;
  }

  .grant-program-wrap td{
    display: flex;
    padding: 0;
    flex-direction: column;
  }
  .grant-program-wrap img{
    display:none;
  }
  .program-benefits {
    padding: 0 0 30px 0 !important;
  }
  .program-benefits img {
    width:100%;
  }
  .prose_affiliate p ,
  .prose_affiliate a{
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .prose_affiliate a {
    height: 22px !important;
  }
  .prose_affiliate li::marker {
    color: inherit;
    font-size: 14px;
  }
  .prose_affiliate ol {
    padding-left: 18px;
    padding-bottom: 20px;
  }
  .prose_affiliate ol li{
    margin: 0;
    padding-left: 4px;
  }
}
.prose_affiliate p ,
.prose_affiliate a{
  font-size: 16px;
  line-height: 26px;
  color: #4C4442;
  letter-spacing: 0.02em;
  text-transform:inherit;
  margin: 0;
}

.prose_affiliate ol {
  padding-left: 18px;
}

.prose_affiliate ol li::marker {
  color: #4C4442;
  font-size: 16px;
  color:inherit;
}

.prose_affiliate a {
  display: inline-block;
  font-weight: 600;
  height: 26px;
}
.grant-program-slider .slider-wrapper{
  margin-top:0;
  padding:0;
}
.grant-program-slider {
  padding: 50px 0 !important;
}
.conservation-partners-slider {
  padding-top: 0px !important;
  padding-bottom: 50px !important;
}
.conservation-partners-slider .slider-wrapper{
  margin-top: 25px;
}
.conservation-partners-slider .slider-button {
  display: flex;
}
@media (min-width:768px) {
  .program-benefits img {
    display: none;
  }
}
.returns-page-step-1 {
    padding-bottom: 20px !important;
}
.returns-page-step-1 article {
    max-width: 630px;
    margin-right: auto;
}
.returns-page-step-1 h3 {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
}
.returns-page-step-1 h3 + p {
    margin-bottom: 24px;
}
.returns-page-step-1 h3 + p + p {
    margin-bottom: 12px;
}
.returns-page-step-1 h3 ~ h3 {
    margin-top: 50px;
}
.returns-page-step-1 p a {
    font-size: 12px;
    letter-spacing: 0.08em;
    color: #000000;
    line-height: 19px;
    display: inline-block;
}
.returnStepOne button{
  padding: 2px 24px 0px;
  width: 196px;
  height: 56px;
  background: #DD5F13;
  border-radius: 2px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.returnStepOne button:disabled{
  background-color: gray;
  border-color: gray;
}



.experience p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #4C4442;
}
.prose.eco-system p {
    font-size: 16px;
    line-height: 26px;
    color: #4C4442;
}


.welcome-home-slider { max-width: unset; }
.welcome-home-slider > div.container { margin-left: 0; }
@media (max-width: 640px) {
  .welcome-home-slider {
    margin-left: 25px !important;
    margin-right: 0;
  }
  .welcome-home-slider > div.container {
    margin-right: 25px;
  }

}
@media (min-width: 640px) {
  .welcome-home-slider {
    margin-left: calc((100vw - 640px) / 2) !important;
  }
  .welcome-home-slider > div.container {
    margin-right: calc((100vw - 640px) / 2) !important;
  }
}
@media (min-width: 768px) {
  .welcome-home-slider {
    margin-left: calc((100vw - 700px) / 2) !important;
  }
  .welcome-home-slider > div.container {
    margin-right: calc((100vw - 700px) / 2) !important;
  }
}
@media (min-width: 1024px) {
  .welcome-home-slider {
    margin-left: calc((100vw - 970px) / 2) !important;
  }
  .welcome-home-slider > div.container {
    margin-right: calc((100vw - 970px) / 2) !important;
  }
}

@media (max-width: 768px) {
  .returnStepOne button{
    width: 100%;
    margin-bottom: 20px;
    order: 0;
  }
}


@media (min-width: 1280px) {
  .welcome-home-slider {
    margin-left: calc((100vw - 1280px) / 2) !important;
  }
  .welcome-home-slider div.container {
    margin-right: calc((100vw - 1280px) / 2) !important;
  }
}
.affiliate-list ol {
    list-style: decimal;
    padding-left: 25px;
}
.affiliate-list p,
.affiliate-list ol li {
    line-height: 26px;
}
.affiliate-list ol li a{
    font-weight: bold;
    line-height: 26px;
    border-bottom: 2px solid #DD5F13;
    display: inline-block;
}
.affiliate-list p a{
    font-weight: bold;
    line-height: 26px;
    border-bottom: 2px solid #DD5F13;
    display: block;
    width: fit-content;
}


.custom_splide_width {
    max-width: 100%;
    margin: 0 auto;
    margin-left: 24px;
}

.splide__arrow:disabled {
  display: none;
}

.splide__arrow--next {
  right: 12% !important;
}

@media(max-width: 1023px) {
  .splide__arrow {
    display: none !important;
  }
}
@media(min-width: 700px) {
    .custom_splide_width {
        margin-left: calc(50vw - 640px /2);
    }
}

@media(min-width: 700px) {
    .custom_splide_width {
        margin-left: calc(50vw - 700px /2);
    }
}

@media(min-width: 1024px) {
    .custom_splide_width {
        margin-left: calc(50vw - 970px /2);
    }
}

@media(min-width: 1280px) {
    .custom_splide_width {
        margin-left: calc(50vw - 1280px /2);
    }
}

.prose-revive h4, .prose-revive h1 {
  text-align: left;
}
.prose-revive h4 {
  margin: 30px auto 20px;
}
.prose-revive h1 {
  margin-top: 50px;
  font-size: 32px;
}
.prose-revive img:first-of-type {
  margin: 50px 0 0;
}
.prose-revive hr {
  margin: 30px auto;
}
.prose-revive td p , .prose-revive > table, .prose-revive ol p, .prose-revive li {
  margin: 0;
}
.prose ul + p {
  margin: 0 !important;
}
.prose ul, .prose ul li:last-of-type, .prose ul li p:last-of-type {
  margin-bottom: 0;
}

.prose-career p, .prose-career ul, .prose-career ul * {
  margin: 0;
}
.prose-career > ul, .prose-career > p {
  margin-bottom: 20px;
}
.prose-career > h6 {
  margin-top: 20px;
  color: black;
  font-size: 14px;
  line-height: 18px;
}
@media(min-width: 768px) {
    .prose-revive {
      position: relative;
    }
    .prose-revive h4, .prose-revive h1 {
      margin: 50px auto 22px;
    }
    .prose-revive h5 {
      text-align: left;
    }
    .prose-revive h1 {
      font-size: 60px;
    }
    .prose-revive h4:last-of-type {
      margin: 30px auto 10px;
    }
    .prose-revive h1 {
      margin-bottom: 30px;
    }
    .prose-revive img:first-of-type {
      margin-top: 100px;
    }
    .prose-revive img:last-of-type {
      margin-bottom: 30px !important;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .prose-revive hr {
      margin: 6px auto 30px;
      border-top: 1px solid #DD5F13 !important;
    }
    .prose-revive td p , .prose-revive > table, .prose-revive ol p, .prose-revive li {
      margin: 0;
    }
    .prose-revive > p {
      margin-bottom: 30px;
    }
    .prose-revive ol {
      margin-bottom: 48px;
    }
    .prose-revive tr {
      border-bottom: none;
    }
    .prose-shipping h5 {
      margin-top: 90px;
    }
    .prose-shipping h3 {
      margin-bottom: 60px;
    }
    .prose-shipping p {
      margin-bottom: 50px;
    }
    .prose-shipping p:last-of-type {
      margin: 0;
    }
    .prose-shipping img {
      padding-bottom: 30px;
    }
}

@media screen and (max-width:767px) {
  .mob-custom-list{
    gap: 10px !important;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
  .mob-custom-list .max-half-mob{ max-width: calc(50% - 20px); height: auto;}
}

@media screen and (max-width:1024px) {
  .mob-custom-list{
    gap: 20px !important;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
  .mob-custom-list .max-half-mob{ max-width: calc(50% - 20px); height: auto;}
}

.g-recaptcha {
  transform: scale(0.75);
}

@media (max-width: 280px) {
  iframe[title='recaptcha challenge'] {
      transform: scale(0.90) !important;
      transform-origin: 35px 35px;
  }
}

@media (min-width: 300px) {
  .g-recaptcha {
      transform: scale(0.85);
  }
}

@media (min-width: 340px) {
  .g-recaptcha {
      transform: scale(1);
      transform-origin: 0 0;
  }
}

.prose.prose-details-card > .container.prose  h1, h2, h3, h4, h5, h6 {
  text-align: inherit !important;
}

.prose.prose-details-card > .container.prose ul, ol {
  padding-left: 1em !important;
  margin-bottom: 1.25em;
}

.custom-message-wrap {
  .container {
    max-width: 100% !important;
  }

  .prose > * {
    overflow:unset !important;
  }
}