  .hero-default {
    min-height: 600px;
  }

  .full-height {
    height: 100vh;
  }

  .short-height {
    min-height: 400px !important;
  }

  .hero-center-block {
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .hero-center-left-block {
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    left: 80px;
  }

  .hero-center-right-block {
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 80px;
  }

  .hero-bottom-left-block {
    justify-content: left;
    bottom: 40px;
    left: 80px;
  }

  .hero-bottom-right-block {
    justify-content: right;
    bottom: 40px;
    right: 80px;
  }
  .hero-plp-custom {
	margin-bottom: 1.25rem;
  }

  .short-height {
    .hero-bottom-left-block,
    .hero-bottom-right-block,
    .hero-bottom-center-block {
      bottom: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .hero-center-left-block {
      justify-content: center;
      right: 0;
      top: 0;
      bottom: 0;
      left: 24px;
    }

    .hero-center-right-block {
      justify-content: center;
      top: 0;
      bottom: 0;
      right: 24px;
    }
    .hero-bottom-left-block {
      justify-content: left;
      bottom: 40px;
      left: 24px;
    }
    .hero-bottom-right-block {
      justify-content: right;
      bottom: 40px;
      right: 24px;
    }
  }

  @media (max-width: 766px) {
    .hero-center-left-block {
      justify-content: center;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .hero-center-right-block {
      justify-content: center;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .hero-bottom-left-block{
      justify-content: left;
      bottom: 40px;
      left: 0;
      right: 0;
    }
    .hero-bottom-right-block {
      justify-content: right;
      bottom: 40px;
      right: 0;
      left: 0;
    }
  }

  .hero-bottom-center-block {
    justify-content: center;
    margin: 0px auto;
    bottom: 40px;
    left: 0;
    right: 0;
  }

  .hero-center-overlay-text-block {
    justify-content: center;
    margin: 0px auto;
    bottom: 35px;
    left: 0;
    right: 0;
  }

  .hero-plp-filter-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px 0px 16px;
    gap: 8px;
    width: 191px;
    height: 56px;
    border-radius: 2px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  .hero-plp-filter-btn-wht {
    border: 2px solid #ffffff;
  }

  .hero-plp-filter-btn-blk {
    border: 2px solid #4c4442;
  }

  .hero-default .btn {
    min-width: 130px;
  }

  .hero-center,
  .hero-bottom-left,
  .hero-bottom-right,
  .hero-bottom-center,
  .hero-center-left,
  .hero-center-right,
  .hero-center-overlay-text,
  .hero-blog-author,
  .module-hero-content {
	min-height: 640px;
	min-height: 89svh;
  }
  .hero-center-inset {
    min-height: 540px;
  }
  .hero-bottom-left-inset {
    min-height: 540px;
  }
  .hero-plp-filter-center,
  .hero-plp-bottom-spread,
  .hero-plp-center,
  .hero-plp-custom,
  .hero-plp-filter-white-bg {
    min-height: 420px;
  }
  .page-header-center,
  .page-header-bottom-left {
    min-height: 375px;
  }
  h1.overlay-text {
    position: absolute;
    top: -250px;
    width: 365px;
    z-index: 2;
  }
  .overlay-text-border {
    position: absolute;
    top: -165px;
    width: 365px;
    left: 0px;
    border-bottom: 20px solid black;
    z-index: 1;
  }

  .promo-title-sub.overlay-text-sub {
    background: black;
    width: 391px;
    text-align: left;
    position: absolute;
    top: -145px;
    right: -40px;
    padding: 5px 10px;
  }
  .promo-title-sub.overlay-text-sub  p {
    font-size: 24px !important;
  }

  .hero-plp-bottom-spread .promo-title-sub p {
    display: inline;
  }
  .hero-plp-bottom-spread .promo-title-sub a {
    float: right;
  }
  .hero-plp-filter-white-bg {
    background: #f7f7f7;
  }

  .module-hero .short-height .desktop-btn {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (min-width: 768px) {
    h1.overlay-text {
        position: absolute;
        top: -250px;
        width: 548px;
        left: -130px;
        z-index: 2;
    }
    .overlay-text-border {
        position: absolute;
        top: -145px;
        width: 548px;
        left: -130px;
        border-bottom: 20px solid black;
        z-index: 1;
    }
    .short-height {
      min-height: 300px !important;
    }
  }
  @media (max-width: 767px) {
    .tablet-image-background,
    .desktop-image-background {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .desktop-image-background,
    .mobile-image-background {
      display: none;
    }
    .promo-title-sub.overlay-text-sub {
      background: black;
      width: 391px;
      text-align: left;
      position: absolute;
      top: -125px;
      right: -120px;
      padding: 5px 10px;
    }
  }

  @media (min-width: 1024px) {
    .promo-title-sub.overlay-text-sub {
      background: black;
      width: 391px;
      text-align: left;
      position: absolute;
      top: -145px;
      right: -120px;
      padding: 5px 10px;
    }
    .tablet-image-background,
    .mobile-image-background {
      display: none;
    }
    h1.overlay-text {
        position: absolute;
        top: -250px;
        width: 548px;
        left: -130px;
        z-index: 2;
    }
    .overlay-text-border {
        position: absolute;
        top: -145px;
        width: 548px;
        left: -130px;
        border-bottom: 20px solid black;
        z-index: 1;
    }
    .hero-center,
    .hero-bottom-left,
    .hero-bottom-right,
    .hero-bottom-center,
    .hero-center-left,
    .hero-center-right,
    .hero-center-overlay-text,
    .hero-blog-author,
    .module-hero-content {
      min-height: 600px;
    }
    .hero-center-inset {
      min-height: 680px;
    }
    .hero-bottom-left-inset {
      min-height: 720px;
    }
    .hero-plp-filter-center,
    .hero-plp-bottom-spread,
    .hero-plp-center,
    .hero-plp-custom,
    .hero-plp-filter-white-bg {
      min-height: 600px;
    }
    .page-header-center,
    .page-header-bottom-left {
      min-height: 540px;
    }

    .desktop-btn {
      width: 250px;
      height: 56px;
      padding: 20px 0;
      font-size: 14px;
    }
  }
