.moduleContent2Blocks__slider,
.moduleContent2Blocks__slider .swiper-slide {
    max-height: var(--container-max-height);
    margin: auto;
}

@media screen and (min-width: 1024px) {
    .moduleContent2Blocks__content-wrapper .swiper-slide {
        display: flex;
    }
}

@media screen and (min-width: 1536px) {
    .moduleContent2Blocks__slider {
        max-height: calc(90vh - 200px);
    }

    .moduleContent2Blocks__slider .swiper-slide {
        max-height: calc(90vh - 220px);
    }
}