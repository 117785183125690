.profile-slider--profile {
    & .content-up {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.profile-slider--pro {
    & .content-up {
        padding-top: 10px;
        padding-bottom: 100px;
    }
}