.marketingSlide__slide.marketingSlide__slide {
  position: relative;
  overflow: hidden;
  height: auto;
  margin-right: 20px;
}

.marketingSlide__track .splide__slide:not(.marketingSlide__slide) {
    max-width: 305px;
}

.marketingSlide__interactive,
.marketingSlide__slide.splide__slide:not(.is-active) .marketingSlide__interactive {
    /* Commented out for now as SWEB-107 February 6, 2025 at 2:07 PM */
    /* overflow: hidden; */
    /* height: var(--content-height-mobile); */
    opacity: 0;
    transition: all 750ms ease-in-out;
}

.marketingSlide__slide.is-active .marketingSlide__interactive {
    /* Commented out for now as SWEB-107 February 6, 2025 at 2:07 PM */
    /* height: var(--active-content-height-mobile); */
    opacity: 1;
    transition: all 750ms ease-in-out;
}

.marketingSlide__slide.marketingSlide__slide .marketingSlide__actionButton.marketingSlide__actionButton--active {
    background-color: transparent !important;
}

.marketingSlide__content ol,
.marketingSlide__content ul,
.marketingSlide__content menu {
    list-style: revert;
    margin-left: 20px;
    padding: 0 !important;
}

.marketingSlide__track .splide__slide:last-child {
    padding-right: 20px !important;
    max-width: calc(305px + 20px);
}

.marketingSlide__video-top-overlay::before,
.marketingSlide__video-bottom-overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0.6;
}

/* Commented out for now as SWEB-107 February 6, 2025 at 2:07 PM */
/* @media screen and (min-width: 768px) {
    .marketingSlide__interactive,
    .marketingSlide__slide.splide__slide:not(.is-active) .marketingSlide__interactive {
        height: 0;
    }

    .marketingSlide__slide.is-active .marketingSlide__interactive {
        height: var(--active-content-height);
    }
} */

@media screen and (min-width: 1024px) {
    .marketingSlide__track .marketingSlide__slide--Wide.splide__slide:last-child {
        padding-right: 20px !important;
        max-width: calc(630px + 20px);
    }
}