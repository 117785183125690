.module-pro-text-content__features {
    a {
        @apply text-primary font-normal underline;
    }
}

@screen md {
    .module-pro-text-content__features {
        ul li {
            position: relative;
            margin-left: 0;
            padding-left: 25px;
        }

        ul li::before {
            border-radius: 100%;
            content: "";
            height: 8px;
            left: 0;
            position: absolute;
            top:7px;
            width: 8px;
            @apply bg-black;
        }

        a {
            @apply text-black font-bold;
        }
    }
}